<template>
	<div class="pre-create">
		<van-cell-group class="pre-group" :border="false">
			<van-field v-model="productInfo.dep" placeholder="请输入始发港" input-align="right" center clearable :readonly="!!productInfo && !!productInfo.id" @input="depInput">
				<template #label>
					<span class="c3">始发港</span>
					<span class="tag">*</span>
				</template>
			</van-field>
			<van-field v-model="productInfo.des" placeholder="请输入目的港" input-align="right" center clearable :readonly="!!productInfo && !!productInfo.id" @input="desInput">
				<template #label>
					<span class="c3">目的港</span>
					<span class="tag">*</span>
				</template>
			</van-field>
			<van-cell center>
				<div class="deliver-date-label">日期</div>
				<div class="deliver-date "  @click="selectDate">
					{{!!date ? date:'请选择'}}
					<van-icon name="arrow" />
				</div>
			</van-cell>
			<!-- <van-field v-model="date" label="日期" placeholder="请选择" input-align="right"  center  @click="selectDate" is-link /> -->
			<van-field v-model="piece" label="件数" placeholder="请输入件数" input-align="right" center type="digit" clearable @input="pieceInput" />
			<van-field v-model="gross_weight" label="毛重(Kg)" placeholder="请输入毛重" input-align="right" center type="digit" clearable @input="weightInput" />
			<van-field v-model="volume" label="体积(m³)" placeholder="请输入体积" input-align="right" center type="number" clearable @blur="volumeChange" />
			<van-field v-model="remark" label="备注：" placeholder="请简单备注货物尺寸、订舱需求等" type="textarea" class="remark-cell" clearable />
		</van-cell-group>
		<div class="pre-bottom">
			<van-button class="create-btn" @click="create" :loading="createLoading">立即询价</van-button>
		</div>
		<van-action-sheet 
			v-model="showDatePicker" 
			title="日期选择"
			@cancel="hideDatePickerHandler"
			class="pre-picker"
			>
			<van-datetime-picker
				type="date"
				:value="currentDate"
				:min-date="minDate"
				@confirm="confirmDate"
				@cancel="hideDatePickerHandler"
				id="date-pick"
			/>
		</van-action-sheet>
	</div>
	
</template>
<script>
	/* eslint-disable */
	import { mapState, mapGetters } from 'vuex';
	import { Dialog } from 'vant';
	import  REQ_HANDLE from '@/utils/request.js'
	export default {
		data: ()=> ({
			productInfo: {},
			volume: '',
			gross_weight: '',
			remark: '',
			piece:'',
			date:'',
			showDatePicker: false,
			currentDate: new Date(),
			minDate: new Date(),
			createLoading: false,
		}),
		computed: {
			...mapState({
				usr: state => state.usr,
				token: state => state.token,
				wx_usr: state => state.wx_usr,
			}),
			...mapGetters({
				login: 'checkLogin',
			})
		},
		created: function() {
			console.log(this.$route.params)
			this.productInfo = Object.assign({}, this.$route.params)
		},
		mounted: function() {
			console.log(this.$route.params)
			// this.$store.commit('updatePageTitle',{title: '创建询单_指尖货运小店'})
		},
		methods: {
			create: async function() {
				if (!this.productInfo.dep || !this.productInfo.des ) {
					this.$toast('请输入始发港/目的港');
					return
				}
				this.createLoading=true
				let that=this,
				    params = {
						agent_id: parseInt(that.productInfo.shopId),
						dep: that.productInfo.dep,
						des: that.productInfo.des,
						volume: that.volume || '',
						gross_weight: that.gross_weight || '',
						deliver_date: that.date || '',
						remark: that.remark || '',
						piece:that.piece || 0
					}
				
				if(!!that.productInfo.id){
					params.product_id=parseInt(that.productInfo.id)
				}
				let res = await REQ_HANDLE.agent.$post('/api-mall/pre-order', params)
				that.createLoading=false
				this.$toast('询价成功');
				that.$router.push('/shop/'+that.productInfo.shopId)
				
			},
			selectDate: function(){
				this.showDatePicker=true
			},
			hideDatePickerHandler: function() {
				this.showDatePicker=false
			},
			confirmDate: function(event) {
				this.date=this.dateFormat(event)
				this.hideDatePickerHandler()
			},
			dateFormat: function(dateData) {
				let date = new Date(dateData),
					y = date.getFullYear(),
					m = date.getMonth() + 1
				m = m < 10 ? ('0' + m) : m
				let d = date.getDate()
				d = d < 10 ? ('0' + d) : d
				const time = y + '-' + m + '-' + d
				return time
			},
			volumeChange:function(){
				if(!!this.volume){
					let volume=Math.abs(this.volume).toFixed(2)
					this.volume = volume
				}else {
					this.volume=''
				}
				
			},
			checkInput: function(port){
				let reg = new RegExp('[^\u4E00-\u9FA5]')
				if(!port){
					return ''
				}else if (!!port && reg.test(port)) {
					let value=port.toUpperCase()
					return value
				}else if(!!port && !reg.test(port)){
					if(port.length>5){
						this.$toast('港口信息不能多于5个字')
						return ''
					}
					return port
				}
			},
			depInput:function(){
				this.productInfo.dep = this.checkInput(this.productInfo.dep)
			},
			desInput:function(){
				this.productInfo.des = this.checkInput(this.productInfo.des)
			},
			pieceInput:function(){
				this.piece =!!this.piece? Math.abs(this.piece):''
			},
			weightInput:function(){
				this.gross_weight = !!this.gross_weight? ''+Math.abs(this.gross_weight):''
			},
		}
	};
</script>
<style scoped >
@import "~@/assets/css/create.css";
</style>